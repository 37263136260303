<template>
  <div>
    <el-form :model="form" class="weekform">
      <el-form-item label="开始时间" prop="startTime">
        <el-date-picker
          v-model="form.startTime"
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="endTime">
        <el-date-picker
          v-model="form.endTime"
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-button type="primary" @click="handleClick">保存</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "OenOen",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        startTime: this.startTime,
        endTime: this.endTime,
      },
    };
  },
  watch: {
    startTime(newVal) {
      this.form.startTime = newVal;
    },
    endTime(newVal) {
      this.form.endTime = newVal;
    },
  },
  methods: {
    handleClick() {
      this.$emit("delete", this.id);
    },
  },
};
</script>
<style lang='less' scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}
.weekform {
  padding: 20px 0;
  display: flex;
  align-items: center;
  .quill-editor {
    width: 375px !important;
    height: 777px !important;
  }
  .text {
    height: 33px;
    line-height: 33px;
    margin-right: 20px;
  }
  /deep/.el-form-item {
    margin-bottom: 0px;
  }
  /deep/.el-form-item__content {
    width: 350px;
  }
}
</style>
